import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  {
    path: "/",
    name: "safrcarewebsite",
    component: () => import("@/components/SkeletonComponent.vue"),

    children: [
      {
        path: "/",
        name: "homepagecomp",
        meta: {
          title: "A Patient-Centric Medical Transport Service Platform ",
          metaTags: [
            {
              name: "description",
              content:
                "The healthcare providers industry can rely on our non-emergency healthcare-focused medical transport software to provide better and more timely care.",
            },
            {
              name: "keywords",
              content: "medical transportation service",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com", 
            },             
          ],
        },
        component: () => import("@/components/HomePage.vue"),
      },
      {
        path: "about-us",
        name: "aboutuscompoent",
        meta: {
          title:
            "About Safr Care - Patient-Centric Healthcare Transportation Platform | About Safr Care",
          metaTags: [
            {
              name: "description",
              content:
                " Reimagining the medical transportation ecosystem with out-of-the box thinking",
            },
            {
              name: "keywords",
              content: "medical transportation service",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/about-us", 
            },            
          ],
        },
        component: () => import("@/components/AboutComponent.vue"),
      },
      {
        path: "/nemt-software",
        name: "nemt-software",
        meta: {
          title: "Safr Care",
          // metaTags: [{
          //         name: 'description',
          //         content: 'Reimagining the medical transportation ecosystem with out-of-the box thinking'
          //     },
          //     {
          //         name: 'keywords',
          //         content: 'medical transportation service'
          //     }, {
          //         name: 'title',
          //         content: ''
          //     }
          // ]
        },
        component: () => import("@/components/RoutingPro.vue"),
      },
      {
        path: "contact-us",
        name: "contactuscompoent",
        meta: {
          title: "Contact us |  Healthcare Transportation Platform",
          metaTags: [
            {
              name: "description",
              content: "We’re here to listen. your comfort is our pride.",
            },
            {
              name: "keywords",
              content: "contact SafrCare medical support",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/contact-us", 
            },             
          ],
        },
        component: () => import("@/components/ContactUsComponent.vue"),
      },
      {
        path: "request-a-demo",
        name: "RquestaDemocompoent",
        meta: {
          title: "Request a Demo - Safr Care",
          metaTags: [
            {
              name: "description",
              content:
                "How safrcare application features work for different segments",
            },
            {
              name: "keywords",
              content: "request demo medical transportation safrcare",
            },
          ],
        },
        component: () => import("@/components/RequestaDemo.vue"),
      },
      {
        path: "join-safr-care",
        name: "JoinSafrCarecompoent",
        meta: {
          title: "Join Safr - Empowering Healthcare Transportation",
          metaTags: [
            {
              name: "description",
              content: "Seamless platform for your NEMT operations",
            },
            {
              name: "keywords",
              content: "Signup safrcare Joinus",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/join-safr-care", 
            },             
          ],
        },
        component: () => import("@/components/JoinSafrcare.vue"),
      },
      {
        path: "solutions/brokers",
        name: "brokersComponent",
        meta: {
          title: "Brokers - Medical Non-Emergency Transport Management | Brokers",
          metaTags: [
            {
              name: "description",
              content:
                "A Powerful Place to House All Rides Safr Care is a next-generation software solution to improve scheduling, routing, billing, reimbursement, passenger information and management",
            },
            {
              name: "keywords",
              content: "non emergency medical transport service",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/solutions/brokers", 
            },             
          ],
        },
        component: () => import("@/components/Solutions/BrokersComponent.vue"),
      },
      {
        path: "solutions/healthcare-provider",
        name: "HealthcareProvider",
        meta: {
          title:
            "Healthcare Providers - Medical Appointment Transportation Services | Healthcare Providers",
          metaTags: [
            {
              name: "description",
              content:
                "Reduce No-Shows and Improve your Operational Efficiencies. we are committed to providing you with resources that remove barriers to healthcare in your community",
            },
            {
              name: "keywords",
              content: "Non-Emergency Medical Transport",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/healthcare-provider", 
            },             
          ],
        },
        component: () =>
          import("@/components/Solutions/HealthcareProvider.vue"),
      },
      {
        path: "solutions/insurance-provider",
        name: "InsuranceProvider",
        meta: {
          title: "Insurance Providers - Verify eligibility for non-emergent transportation | Insurance Providers",
          metaTags: [
            {
              name: "description",
              content:
                "Integration with Safr Care software for eligibility verification, pre-authorization, and billing to reduce costs",
            },
            {
              name: "keywords",
              content: "medical non emergency transport",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/solutions/insurance-provider", 
            },             
          ],
        },
        component: () => import("@/components/Solutions/InsuranceProvider.vue"),
      },
      {
        path: "solutions/patients",
        name: "PatientsComponent",
        meta: {
          title: "Patients - Assisted Transportation for Non-Emergency Appointments | Patients",
          metaTags: [
            {
              name: "description",
              content: "Empower Patients and Improve Health Outcomes",
            },
            {
              name: "keywords",
              content: "Patients",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/solutions/patients", 
            },             
          ],
        },
        component: () => import("@/components/Solutions/PatientsComponent.vue"),
      },
      {
        path: "solutions/transportation-provider",
        name: "TransportationProvider",
        meta: {
          title:
            "NEMT Providers | Next-Generation NEMT Dispatch and Routing | NEMT Providers",
          metaTags: [
            {
              name: "description",
              content:
                "Transportation Providers Safr Care is comprehensive platform provides the industries most efficient solution that addresses multiple changes faced by today healthcare providers.",
            },
            {
              name: "keywords",
              content: "Non-Emergency Medical Transport",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/solutions/transportation-provider", 
            },             
          ],
        },
        component: () =>
          import("@/components/Solutions/TransportationProvider.vue"),
      },
      {
        path: "media/blogsdetail/:blogTitle?",
        name: "BlogsDetailComponent",
        meta: {
          title: "Blogs - Detailed Insights and Stories from the NEMT Industry | Blogs",
          metaTags: [
            {
              name: "description",
              content: "Explore in-depth articles and stories about the latest trends, partnerships, and innovations in the non-emergency medical transportation (NEMT) industry. Stay informed and ahead with Safr Care.",
            },
            {
              name: "keywords",
              content: "Non-Emergency Medical Transportation, NEMT, Medical Transport, Safr Care Blogs",
            },
            {
              name: "title",
              content: "Safr Care Blog Detail",
            },
            {
              rel: "canonical",
              href: (route) => `https://www.safrcare.com/media/blogsdetail/${route.params.blogTitle || ""}`,
            },
          ],
        },
        component: () => import("@/components/Media/BlogsDetailPage.vue"),
      },
      {
        path: "media/blogs",
        name: "BlogsComponent",
        meta: {
          title:
            "Safr Care Blog: Stories and Insights on Non-Emergency Medical Transportation",
          metaTags: [
            {
              name: "description",
              content:
                "Safr Care’s blog provides valuable insights on non-emergency medical transportation, patient care, transportation regulations, healthcare policies, and more.",
            },
            {
              name: "keywords",
              content: "Safr Care Blogs,Medical Transportation Service,Patient Care",
            },
            {
              name: "title",
              content: "Safr Care Blogs",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/media/blogs", // Static URL for the blog listing page
            },            
          ],
        },
        component: () => import("@/components/Media/BlogsComponent.vue"),
      },
      {
        path: "media/news",
        name: "NewsComponent",
        meta: {
          title: "Safr Care News: Latest Stories and Updates",
          metaTags: [
            {
              name: "description",
              content:
                "Stay updated with the latest news and updates from Safr Care. Our blog covers important stories, insights, and developments in non-emergency medical transportation and related fields.",
            },
            {
              name: "keywords",
              content:
                "Safr Care News, Non-Emergency Medical Transportation, Healthcare Updates",
            },
            {
              name: "title",
              content: "Safr Care News",
            },
            {
              rel: "canonical",
              href: "https://www.safrcare.com/media/news", // Static URL for the blog listing page
            },            
          ],
        },
        component: () => import("@/components/Media/NewsComponent.vue"),
      },
      {
        path: "media/newsdetail/:newsTitle?",
        name: "NewsDetailComponent",
        meta: {
          title: "Safr Care: Latest News in Non-Emergency Medical Transportation",
          metaTags: [
            {
              name: "description",
              content: "Get the latest news and updates on non-emergency medical transportation (NEMT). Stay informed about industry developments, new regulations, and company news with Safr Care.",
            },
            {
              name: "keywords",
              content: "Non-Emergency Medical Transportation, NEMT News, Medical Transport Updates, Safr Care News",
            },
            {
              name: "title",
              content: "Safr Care News Detail",
            },
            {
              rel: "canonical",
              href: (route) => `https://www.safrcare.com/media/newsdetail/${route.params.newsTitle || ""}`,
            },
          ],
        },
        component: () => import("@/components/Media/NewsDetailPage.vue"),
      },
      
    ],
  },
  {
    path: "/terms/terms-of-use",
    name: "TermsofuseComponent",
    meta: {
      title:
        "Terms and Conditions - Patient-Centric Medical Transport Service Platform",
      metaTags: [
        {
          name: "description",
          content:
            "Review our terms and conditions for comprehensive insights into our patient-focused medical transport software, ensuring reliable and efficient service for healthcare providers.",
        },
        {
          name: "keywords",
          content:
            "medical transport terms, service platform agreements, healthcare transport regulations",
        },
        {
          name: "title",
          content:
            "Terms and Conditions | Patient-Centric Medical Transport Service Platform",
        },
        {
          rel: "canonical",
          href: "https://www.safrcare.com/terms/terms-of-use", // Static URL for the blog listing page
        },         
      ],
    },
    component: () => import("@/components/Terms/TermsofUse.vue"),
  },
  {
    path: "/terms/privacy-policy",
    name: "PrivacyPolicyComponent",
    meta: {
      title:
        "Privacy Policy - Patient-Centric Medical Transport Service Platform",
      metaTags: [
        {
          name: "description",
          content:
            "Our privacy policy outlines how we handle personal information on our medical transport service platform, ensuring confidentiality and compliance with privacy regulations.",
        },
        {
          name: "keywords",
          content:
            "medical transport privacy, data protection policy, healthcare information security",
        },
        {
          name: "title",
          content:
            "Privacy Policy | Patient-Centric Medical Transport Service Platform",
        },
        {
          rel: "canonical",
          href: "https://www.safrcare.com/terms/privacy-policy", // Static URL for the blog listing page
        },         
      ],
    },
    component: () => import("@/components/Terms/PrivacyPolicy.vue"),
  },
  {
    path: "/transportation-files/vehicle-standards",
    name: "VehicleStandardsComponent",
    meta: {
      title: "Vehicle Standards of Safr Care",
      metaTags: [
        {
          name: "description",
          content:
            "Minimum standards for vehicles used in Safr Care transportation services, including requirements for compliance, vehicle specifications, and wheelchair securement standards.",
        },
        {
          name: "keywords",
          content:
            "Safr Care, transportation, vehicle standards, compliance, wheelchair securement",
        },
        {
          name: "title",
          content: "Vehicle Standards of Safr Care",
        },
      ],
    },
    component: () =>
      import("@/components/TransportationFiles/VehicleStandards.vue"),
  },

  {
    path: "/transportation-files/driver-standards",
    name: "DriverStandardsComponent",
    meta: {
      title: "Safr Care NEMT Driver Standards",
      metaTags: [
        {
          name: "description",
          content:
            "Safr Care NEMT Driver Standards ensure professionalism, safety, and quality service for members.",
        },
        {
          name: "keywords",
          content:
            "Safr Care NEMT, driver standards, transportation, non-emergency medical transportation, safety, professionalism, service quality",
        },
        {
          name: "title",
          content: "Safr Care NEMT Driver Standards",
        },
      ],
    },
    component: () =>
      import("@/components/TransportationFiles/DriverStandards.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Safr Care"; // Set the title dynamically
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
export default router;
